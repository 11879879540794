export function onceWheel(root: HTMLElement, scrollDownHandler: () => void, scrollUpHandler: () => void) {
    let wheelPower = 0
    let wheelLock = false
    let wheelTimeStamp = 0
    let wheelLockTimer = null
    const deltaThreshold = 4
    const noiseThreshold = 2
    const wheelEvent = 'onwheel' in document ? 'wheel'
        : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll'

    const wheelFunction = (event: WheelEvent) => {
        const delta = event.deltaY * -1
        const absDelta = Math.abs(delta)

        if (absDelta < noiseThreshold) return
        if (event.timeStamp - wheelTimeStamp < 320 && wheelLock) return

        wheelTimeStamp = event.timeStamp

        if (wheelPower < absDelta && !wheelLock) {
            if (delta < -deltaThreshold) scrollDownHandler()
            else if (delta > deltaThreshold) scrollUpHandler()

            lock(absDelta)

            clearTimeout(wheelLockTimer)
            wheelLockTimer = setTimeout(() => {
                if (wheelPower !== absDelta) return
                unlock()
            }, 2000)
        } else if (absDelta < deltaThreshold) {
            unlock()
        }
    }

    root.addEventListener(wheelEvent, wheelFunction, { passive: true })

    function lock(absDelta: number) {
        wheelPower = absDelta
        wheelLock = true
    }

    function unlock() {
        wheelPower = deltaThreshold
        wheelLock = false
    }

    return () => root.removeEventListener(wheelEvent, wheelFunction)
}