import Lenis from "lenis";
import {animate, frame, inView, scroll, stagger, delay} from "motion";
import ImageSequence from "../utils/image-sequence";
import {onceWheel} from "../utils/once-wheel";
import {handleTouch} from "../utils/handle-touch";

const initMotion = () => {
    const smoothScroll = new Lenis({ anchors: { offset: -40 } }),
        raf = time => smoothScroll.raf(time.timestamp)

    frame.update(raf, true);

    (() => {
        const homeIntro = document.querySelector<HTMLVideoElement>('[data-home-intro]'),
            homeLoop = document.querySelector<HTMLVideoElement>('[data-home-loop]'),
            header = document.querySelector<HTMLElement>('[data-header]')

        if (!homeLoop) return
        if (window.scrollY > 80) header.style.opacity = '1'

        animate([
            [ header, { opacity: 1 }, { duration: homeIntro ? 1.6 : .4 } ],
            [ ".HomeHeroCover > div", { transform: ["translateY(-2.5rem)"] }, { duration: .8, at: '-.4', } ],
            [ ".HomeHeroIntro small", { filter: "opacity(1)" }, { at: '-.4', duration: .4 } ],
        ], { delay: homeIntro ? 2.4 : 0 })

        scroll(animate(".HomeHeroCover", { transform: "translateY(-100%)" }), {
            target: homeLoop,
            offset: ["end end", "end start"]
        })

        homeIntro?.addEventListener("ended", () => {
            homeLoop.play().then(() => homeIntro.parentElement.classList.add('u-hidden'))
        })

        const fade = animate(".HomeHeroIntroduction p", {
            opacity: [0, 1],
            transform: ["translateY(0)", "translateY(-20vh)"]
        }, {
            delay: stagger(.12),
            duration: .12,
            transform: { duration: 1 }
        })

        scroll(fade, {
            target: document.querySelector(".HomeHeroIntroduction"),
            offset: ["start 80vh", "end start"]
        })
    })();

    (async () => {
        const showroomWalkthrough = document.querySelector<HTMLElement>("[data-showroom-walkthrough]"),
            cover = showroomWalkthrough?.querySelector('picture'),
            canvas = showroomWalkthrough?.querySelector('canvas');

        if (!showroomWalkthrough) return;
        const imageSequence = new ImageSequence(canvas)

        inView(cover, () => {
            if (!imageSequence.isLoaded) {
                smoothScroll.stop()
                smoothScroll.scrollTo(showroomWalkthrough.offsetTop, { duration: 0, force: true })
            }

            //animate(showroomWalkthrough.querySelector('aside'), { y: [ '200%', 0 ]})
        }, {
            amount: "all"
        })

        const fadeCoverSticky = animate(cover, { opacity: [0, 1], transform: ["translateY(-12vh)", "translateY(0)"] }, { duration: 4 }),
            cancelStickyCover = scroll(fadeCoverSticky, {
            target: showroomWalkthrough,
            offset: ["start 80vh", "start 40vh"]
        })

        const setupWalkthrough = () => {
            imageSequence.loadFrames().then(() => {
                cancelStickyCover();

                const fadeCoverFull = animate([
                    [ cover, { opacity: [0, 1], transform: ["translateY(-12vh)", "translateY(0)"] }, { duration: 4 } ],
                    [ cover, { opacity: [1, 0] }, { duration: 2, at: 8 } ],
                    [ canvas, { opacity: [0, 1] }, { duration: 2, at: '<' } ],
                    [ cover.querySelector('img'), { transform: ["scale(1)", "scale(1.12)"] }, { duration: 2, at: '<' } ]
                ])

                scroll(fadeCoverFull, {
                    target: showroomWalkthrough,
                    offset: ["start 80vh", "120vh end"]
                })

                smoothScroll.isStopped && smoothScroll.start()
                inView(cover, () => smoothScroll.scrollTo(showroomWalkthrough.offsetTop + window.innerHeight * .24, {
                    duration: 2.4
                }), {
                    margin: "0px 0px -80% 0px"
                })

                imageSequence.loadFrames();
            })

            const anchors = Array.from(showroomWalkthrough.querySelectorAll<HTMLButtonElement>('aside nav > button') ?? []);

            scroll((progress: number) => {
                const frameIndex = Math.floor(progress * (imageSequence.frames.length - 1));
                if (!imageSequence.isLoaded || imageSequence.currentFrame === frameIndex) return;

                imageSequence.drawFrame(frameIndex);

                const active = Math.max(1, Math.min(Math.round(progress * 6), anchors.length))
                anchors.forEach(anchor => anchor.classList.toggle('is-active', anchors.indexOf(anchor) + 1 === active))
            }, {
                target: showroomWalkthrough,
                offset: ["100vh end", "440vh end"]
            });

            anchors.forEach(anchor => anchor.addEventListener("click", () => {
                !anchor.classList.contains('is-active')
                && smoothScroll.scrollTo(showroomWalkthrough.offsetTop + ((showroomWalkthrough.offsetHeight - window.innerHeight) / 5 * (anchors.indexOf(anchor) + .4)), { duration: 2.4 })
            }))
        }

        inView(showroomWalkthrough, () => setupWalkthrough(), {
            margin: "0px 0px -50% 0px"
        })

        showroomWalkthrough.querySelector('[data-skip-walkthrough]')?.addEventListener('click', () => {
            !smoothScroll.isStopped && smoothScroll.stop()
            smoothScroll.scrollTo(showroomWalkthrough.offsetTop + showroomWalkthrough.offsetHeight - window.innerHeight * .96, { force: true })
        })
    })();

    (() => {
        const modelsPresentation = document.querySelector<HTMLElement>('[data-models-presentation]'),
            slides = Array.from(modelsPresentation?.querySelectorAll<HTMLElement>('[data-model-slide]') ?? [])

        if (!modelsPresentation || !slides.length) return

        const fadeIn = animate([
            [ modelsPresentation, { opacity: 1 } ],
            [ slides[0].querySelector('img'), { transform: ["scale(.88)", "scale(1)"] }, { at: '<' } ]
        ])

        inView(slides[0].parentElement, () => {
            modelsPresentation.classList.add('in-view')
            return () => modelsPresentation.classList.remove('in-view')
        }, {
            amount: "all"
        })

        scroll(fadeIn, {
            target: modelsPresentation,
            offset: ["100vh end", "120vh end"]
        })

        const virtualHeight = parseInt(getComputedStyle(modelsPresentation).getPropertyValue('--ModelSlideVirtualHeight').replace('vh', '')),
            totalHeight = 100 + 4 * virtualHeight,
            isTouchDevice = ('ontouchstart' in window || navigator.maxTouchPoints > 0);

        const modelPosition = [
            { range: [0, 24], position: 12 },
            { range: [25, 49], position: 32 },
            { range: [50, 74], position: 64 },
            { range: [75, 100], position: 88 }
        ];

        const moveActiveModel = (direction: "next" | "prev") => {
            const currentPosition = Math.max(0, Math.min(Math.floor((window.scrollY - modelsPresentation.offsetTop - window.innerHeight * .2) / (window.innerHeight * (totalHeight - 100) / 100) * 100), 100)),
                currentIndex = modelPosition.findIndex(model => currentPosition >= model.range[0] && currentPosition <= model.range[1]);

            const directionIndex= currentIndex + (direction === 'next' ? 1 : -1)
            let directionPosition = modelsPresentation.offsetTop - window.innerHeight * .4

            if (directionIndex > 3) {
                directionPosition = modelsPresentation.offsetTop + modelsPresentation.offsetHeight
            } else if (directionIndex >= 0) {
                directionPosition = modelsPresentation.offsetTop + (window.innerHeight * .2) + (window.innerHeight * ((totalHeight - 100) / 100) * (modelPosition[directionIndex].position / 100))
            }

            console.log(direction, directionIndex, smoothScroll.isScrolling)
            smoothScroll.scrollTo(directionPosition, { force: true, immediate: directionIndex >= 0 && directionIndex <= 3, duration: directionIndex > 0 ? 4 : 1.6, lock: true })
        }

        const moveToNextModel = () => moveActiveModel("next")
        const moveToPrevModel = () => moveActiveModel("prev")

        const moveModelWithKeyboard = (e: KeyboardEvent) => {
            ["ArrowRight", "ArrowDown", "PageDown"].includes(e.key) && moveActiveModel("next");
            ["ArrowLeft", "ArrowUp", "PageDown"].includes(e.key) && moveActiveModel("prev");
        }

        const atStart = () => window.scrollY < modelsPresentation.offsetTop + modelsPresentation.offsetHeight / 2
        let cancelWheelEvent = () => {}
        const stopModelsScroll = () => {
            smoothScroll.stop()
            console.log(smoothScroll.isScrolling, 'scrolled from start/end')
            smoothScroll.scrollTo(atStart() ? modelsPresentation.offsetTop + window.innerHeight * .2
                : modelsPresentation.offsetTop + (window.innerHeight * totalHeight / 100) - window.innerHeight, { force: true, duration: atStart() ? 1.6 : 4, lock: true })

            setTimeout(() => {
                cancelWheelEvent = !isTouchDevice ? onceWheel(modelsPresentation, moveToNextModel, moveToPrevModel)
                    : handleTouch(modelsPresentation, moveToNextModel, moveToPrevModel)
            }, 800)
        }

        let delayModelsLock = false
        const watchModelsLockDelay = () => {
            delayModelsLock = true
            setTimeout(() => { delayModelsLock = false }, 800)
        }

        inView(modelsPresentation.querySelector('aside'), () => {
            if (!delayModelsLock) {
                document.addEventListener("keydown", moveModelWithKeyboard)
                !isTouchDevice ? document.addEventListener('wheel', stopModelsScroll, { once: true }) : stopModelsScroll()
            }

            return () => {
                console.log('left models from viewport')
                document.removeEventListener("keydown", moveModelWithKeyboard)
                !isTouchDevice && document.removeEventListener('wheel', stopModelsScroll)

                smoothScroll.isStopped && smoothScroll.start()
                cancelWheelEvent()
                watchModelsLockDelay()
            }
        })

        scroll((progress:number) => {
            const active = Math.max(1, Math.min(Math.round(progress * 4), slides.length))
            slides.forEach(slide => slide.classList.toggle('is-visible', slides.indexOf(slide) + 1 === active))
        }, {
            target: modelsPresentation,
            offset: ["100vh end", `${totalHeight}vh end`]
        })

        scroll(animate(modelsPresentation, { clipPath: "inset(0 0 100vh)" }), {
            target: modelsPresentation,
            offset: [`${totalHeight}vh end`, `${totalHeight + 50}vh end`]
        })
    })();

    (() => {
        const bespokeIntroduction = document.querySelector('[data-bespoke-introduction]'),
            universeHolder = document.querySelector('[data-universe]')

        universeHolder && inView(universeHolder.parentElement, () => {
            for (let i = 0; i < (window.innerWidth > 960 ? 240 : 120); i++) {
                const star = document.createElement('star')
                star.style.top = `${100 * Math.random()}%`
                star.style.left = `${100 * Math.random()}%`

                universeHolder.appendChild(star)
            }

            const shineStar = (star: HTMLElement) => {
                return animate(star, { opacity: Math.random() }, { duration: Math.random() * .5 + .5 })
                    .then(() => shineStar(star))
            }

            universeHolder.querySelectorAll<HTMLElement>('star').forEach(star => shineStar(star))
        })

        if (!bespokeIntroduction) return

        const fadeIn = animate([
            [ universeHolder, { opacity: [0, 1] }, { duration: .2 } ],
            [ ".BespokeIntroductionInner :is(h2, p, [data-button-holder])", { opacity: [0, 1], transform: ["translateY(2rem)", "translateY(0)"] }, { delay: stagger(.08) }],
            [ ".BespokeIntroductionInner", { transform: ["translateY(0)", "translateY(-50%)"] }, { at: '+.4', duration: .8 } ],
            [ ".BespokeIntroductionAtelier", { clipPath: 'inset(0)' }, { at: '<', duration: .8 } ],
            [ ".BespokeIntroductionAtelier > img", { transform: ["translateY(-16vh)", "translateY(0)"] }, { at: '<', duration: 1.32 } ]
        ])

        scroll(fadeIn, {
            target: bespokeIntroduction,
            offset: ["100vh end", "end start"]
        })
    })();

    (() => {
        const modelSelection = document.querySelector('[data-model-selection]'),
            modelOffers = document.querySelectorAll('[data-model-offers]')

        if (!modelSelection) return

        modelOffers.forEach(model => {
            inView(model, () => {
                animate(`[data-model-offers]#${model.id} > *`, {
                    opacity: 1,
                    transform: ["translateY(3.5rem)", "translateY(0)"]
                }, {
                    duration: .8,
                    delay: stagger(.2)
                })
            }, {
                margin: "0px 0px -12% 0px"
            })
        })
    })();
}

export default initMotion