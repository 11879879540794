export function handleTouch(root: HTMLElement, swipeDownHandler: () => void, swipeUpHandler: () => void) {
    let touchstartX = 0,
        touchstartY = 0,
        touchendX = 0,
        touchendY = 0;

    function handleGesture(touchStartX: number, touchStartY: number, touchEndX: number, touchEndY: number) {
        const deltaX = touchEndX - touchStartX;
        const deltaY = touchEndY - touchStartY;

        if (Math.abs(deltaX) > Math.abs(deltaY))
            deltaX > 0 ? swipeUpHandler() : swipeDownHandler()
        else if(Math.abs(deltaX) < Math.abs(deltaY))
            deltaY > 0 ? swipeUpHandler() : swipeDownHandler()
    }

    const handleTouchStart = (event: TouchEvent) => {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }

    const handleTouchEnd = (event: TouchEvent) => {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;

        handleGesture(touchstartX, touchstartY, touchendX, touchendY);
    }

    root.addEventListener('touchstart', handleTouchStart, false);
    root.addEventListener('touchend', handleTouchEnd, false);

    return () => {
        root.removeEventListener('touchstart', handleTouchStart, false);
        root.removeEventListener('touchend', handleTouchEnd, false);
    }
}