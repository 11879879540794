const initModal = () => {
    const prepareModal = (opener: HTMLElement, key: string) => {
        const modal = document.querySelector<HTMLDialogElement>(`dialog#${key}`)
        if (!modal) return

        opener.addEventListener("click", () => modal.showModal())
        if (modal.hasAttribute('data-initialized')) return

        modal.querySelector('[data-modal-closer]')?.addEventListener("click", () => modal.close())
        modal.addEventListener('click', e => e.target === modal && modal.close())

        modal.setAttribute('data-initialized', '')
    }

    document.querySelectorAll<HTMLElement>('[data-modal]')
        .forEach(opener => prepareModal(opener, opener.dataset.modal))

    document.querySelectorAll<HTMLElement>('body.single-model :is([href$="#mam-zajem"], [href$="#i-am-interested"])')
        .forEach(opener => prepareModal(opener, 'interest'))
}

export default initModal